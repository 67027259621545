import React, { useState } from "react"
import logo from "../images/logo.png"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const PrivacyPolicy = () => {
  const [visible, setVisible] = useState(false)
  return (
    <div>
      {/* SECTION -1 */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>RouteCap</title>
      </Helmet>
      <div
        className="md:grid lg:grid-rows-6 md:grid-rows-8 lg:h-screen why-routecap-banner"
        id="home"
      >
        <div className="md:row-span-1">
          <header className="lg:px-16 px-6 bg-purple flex flex-wrap items-center lg:py-0 py-2 nav-background-whyUs ">
            <div className="flex-1 flex justify-between items-center">
              <a href="/">
                <img src={logo} className="logo mt-4" />
              </a>
            </div>

            <label
              class="pointer-cursor lg:hidden block"
              onClick={() => setVisible(!visible)}
            >
              <svg
                className="fill-current "
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <title>menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </label>
            <div
              className={
                visible
                  ? "visible-class hidden lg:flex lg:items-center lg:w-auto w-full"
                  : "hidden-class hidden lg:flex lg:items-center lg:w-auto w-full"
              }
              id="menu"
            >
              <nav>
                <ul class="lg:flex items-center justify-between text-base text-white pt-4 lg:pt-0">
                  <li>
                    <Link
                      to="/"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      <strong>Home</strong>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/why-routecap/"
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      <strong> Why RouteCap?</strong>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy/"
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      <strong>Privacy Policy</strong>
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/home#modules"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Modules
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                      href="/home/#modules"
                    >
                      Modules
                    </a> */}
                  {/* </li> */}
                  {/* <li>
                    <Link
                      to="/home#features"
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Features
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                      href="/home/#features"
                    >
                      Features
                    </a> */}
                  {/* </li>
                  <li>
                    <Link
                      to="/home#industries"
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Industries
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                      href="http://localhost:8000/home/#industries"
                    >
                      Industries
                    </a> */}
                  {/* </li>
                  <li>
                    <Link
                      to="/home#contact-us"
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      Contact Us
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                      href="/home#contact-us"
                    >
                      Contact Us
                    </a> */}
                  {/* </li> */}
                </ul>
              </nav>
            </div>
          </header>
        </div>
      </div>
    </div>
  )
}
export default PrivacyPolicy
